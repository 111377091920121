import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Typography from "root/components/Typography";
import Button from "root/components/Button";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import styles from "./index.module.css";

const mobileW = 800;

const query = graphql`
  query JobOffersQuery {
    allJobOffersJson {
      nodes {
        position
        formLink
      }
    }
  }
`;

const CareersJoinUs = ({ translations: { title, buttonLabel } }) => {
  const data = useStaticQuery(query);
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Typography color="blue" variant="h2" weight="medium">
          {title}
        </Typography>
        <div className={styles.offers}>
          {data.allJobOffersJson.nodes.map(offer => (
            <div className={styles.offer}>
              <div className={styles.grid}>
                <Grid
                  columns={dimensions.width > mobileW ? 36 : 18}
                  lines={9}
                  color="yellow"
                  opacity={0.5}
                />
              </div>
              <div className={styles.offerContent}>
                <div className={styles.offerPosition}>
                  <Typography color="black" weight="medium" variant="h3">
                    {offer.position}
                  </Typography>
                </div>
                <a
                  href={offer.formLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.offerButton}
                >
                  <Button color="yellow" size="medium">
                    <Typography weight="medium">{buttonLabel}</Typography>
                  </Button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CareersJoinUs.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default CareersJoinUs;
