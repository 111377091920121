import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Typography from "root/components/Typography";
import TeamElement from "root/components/TeamElement";

import styles from "./index.module.css";

const query = graphql`
  query TeamQuery {
    allTeamJson {
      nodes {
        name
        position
        photo {
          childImageSharp {
            fluid(maxWidth: 200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const CareersTeam = ({ translations: { title } }) => {
  const data = useStaticQuery(query);

  return (
    <div>
      <div className={styles.root}>
        <div className={styles.content}>
          <Typography color="blue" variant="h2" weight="medium">
            {title}
          </Typography>
        </div>
      </div>
      <div className={styles.team}>
        {data.allTeamJson.nodes.map(member => (
          <div className={styles.member}>
            <TeamElement
              photo={member.photo}
              name={member.name}
              position={member.position}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

CareersTeam.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default CareersTeam;
