import React from "react";
import PropTypes from "prop-types";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Navbar from "root/components/Navbar";
import CareersHero from "root/sections/CareersHero";
import CareersPerks from "root/sections/CareersPerks";
import CareersTeam from "root/sections/CareersTeam";
import CareersJoinUs from "root/sections/CareersJoinUs";
import Footer from "root/components/Footer";
import withTrans from "root/i18n/withTrans";

/* eslint-disable id-length */
const CareersPage = ({ t }) => {
  return (
    <div>
      <SEO title={t("pages.careers.label")} description="" keywords="" />
      <Layout>
        <Navbar color="blue" currentPage="none" translations={t("pages")} />
        <CareersHero translations={t("careers.hero")} />
        <CareersPerks translations={t("careers.perks")} />
        <CareersTeam translations={t("careers.team")} />
        <CareersJoinUs translations={t("careers.joinUs")} />
        <Footer translations={t("pages")} />
      </Layout>
    </div>
  );
};

CareersPage.propTypes = {
  t: PropTypes.func.isRequired,
};
/* eslint-enable id-length */

export default withTrans(CareersPage);
