import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import stick from "root/assets/careers-hero-stick.svg";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import styles from "./index.module.css";

const mobileW = 800;

const CareersHero = ({ translations: { title } }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(relativePath: { eq: "careers-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <div className={styles.titleText}>
          <Typography color="blue" variant="h1" weight="medium">
            {title}
          </Typography>
        </div>
        <div className={styles.backGrid}>
          <Grid
            columns={dimensions.width > mobileW ? 45 : 18}
            lines={dimensions.width > mobileW ? 18 : 36}
            color="yellow"
            opacity={0.5}
          />
        </div>
      </div>
      <div className={styles.images}>
        <div className={styles.teamImage}>
          <Img
            className={styles.image}
            alt="team photo"
            fluid={data.heroImg.childImageSharp.fluid}
            objectFit="cover"
          />
        </div>
        <div className={styles.stick}>
          <img alt="stick" src={stick} />
        </div>
      </div>
    </div>
  );
};

CareersHero.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default CareersHero;
