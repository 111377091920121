import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import iconBox from "root/assets/careers-perks-box.svg";
import iconHorn from "root/assets/careers-perks-horn.svg";
import iconMicro from "root/assets/careers-perks-micro.svg";
import iconIsland from "root/assets/careers-perks-island.svg";
import iconBooks from "root/assets/careers-perks-books.svg";
import iconGift from "root/assets/careers-perks-gift.svg";
import iconApple from "root/assets/careers-perks-apple.svg";

import styles from "./index.module.css";

const mobileW = 800;

const CareersPerks = ({
  translations: {
    title,
    firstPerk,
    secondPerk,
    thirdPerk,
    fourthPerk,
    fifthPerk,
    sixthPerk,
    seventhPerk,
  },
}) => {
  const data = useStaticQuery(graphql`
    query {
      perksImg1: file(relativePath: { eq: "careers-perks1.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      perksImg2: file(relativePath: { eq: "careers-perks2.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      perksImg3: file(relativePath: { eq: "careers-perks3.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const dimensions = useWindowDimensions();

  const renderPerk = (perk, img) => {
    return (
      <div className={styles.perk}>
        <img alt="perk icon" src={img} className={styles.iconImg} />
        <Typography color="black">{perk}</Typography>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Typography color="blue" variant="h2" weight="medium">
          {title}
        </Typography>
        <div className={styles.imageRoot}>
          <div className={styles.backGrid}>
            <Grid
              columns={dimensions.width > mobileW ? 45 : 18}
              lines={dimensions.width > mobileW ? 36 : 63}
              color="yellow"
              opacity={0.4}
            />
          </div>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.firstBlock}>
            <div className={styles.imageBox}>
              <Img
                className={styles.leftImg}
                alt="perks photo"
                fluid={data.perksImg1.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
            <div className={styles.perksList}>
              {renderPerk(firstPerk, iconBox)}
              {renderPerk(secondPerk, iconHorn)}
              {renderPerk(thirdPerk, iconMicro)}
              {renderPerk(fourthPerk, iconIsland)}
              {renderPerk(fifthPerk, iconBooks)}
              {renderPerk(sixthPerk, iconGift)}
              {renderPerk(seventhPerk, iconApple)}
            </div>
          </div>
          <div className={styles.secondBlock}>
            <div className={styles.imageBox}>
              <Img
                className={styles.leftImg}
                alt="perks photo"
                fluid={data.perksImg2.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
            <div className={styles.imageBox}>
              <Img
                className={styles.rightImg}
                alt="perks photo"
                fluid={data.perksImg3.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CareersPerks.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
    firstPerk: PropTypes.string.isRequired,
    secondPerk: PropTypes.string.isRequired,
    thirdPerk: PropTypes.string.isRequired,
    fourthPerk: PropTypes.string.isRequired,
    fifthPerk: PropTypes.string.isRequired,
    sixthPerk: PropTypes.string.isRequired,
    seventhPerk: PropTypes.string.isRequired,
  }).isRequired,
};

export default CareersPerks;
