import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Typography from "root/components/Typography";

import styles from "./index.module.css";

const TeamElement = ({ photo, name, position }) => {
  return (
    <div>
      <Img
        className={styles.elementImg}
        alt="member photo"
        fluid={photo.childImageSharp.fluid}
        objectFit="cover"
      />
      <div className={styles.elementName}>
        <Typography color="black">{name}</Typography>
      </div>
      <Typography color="black" variant="smallBody">
        {position}
      </Typography>
    </div>
  );
};

TeamElement.propTypes = {
  photo: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({}),
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

export default TeamElement;
